import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class EmployeeDebitCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }
    
      componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };

    render() {
        const { isLoading } = this.state;
        return (
            <>
            <Helmet>
                <title>Tokenised Bank Liabilities | Kazh.com</title>
            </Helmet>

            {isLoading ? (
          <Preloader />
        ) : (
          <>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{width: '70%',}}>
                                    <h1 data-aos="fade-down">Tokenised Bank Liabilities</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1>

                                Traditional banking liabilities</h1>
                                <p>Tokenized bank liabilities on CBDC or wrapped token platforms represent traditional banking liabilities, such as deposits and liabilities in digital token form. </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">A digital asset</h1>
                                <p data-aos="fade-down"> A CBDC-based wrapped token is a digital asset representing central bank digital currency (CBDC) that has been tokenized on a blockchain network. This wrapped token mirrors the value of the CBDC it is pegged to, allowing it to be utilized within CBDC eco system..</p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/em_logo_Card.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                {/* <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down"> </h1>
                                <p data-aos="fade-down">
                               </p>

                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Track all payments in real time</h1>
                                <p data-aos="fade-down">Instantly see consolidated online spending in the payments interface. Stay up to date on budgets and detect unusual transactions.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-1.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section> */}
               
                <Footer />
                </>
        )}
            </>
        );
    }
}

export default EmployeeDebitCard;
