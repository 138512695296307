import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }
    
      componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };
    

    render() {
        const { isLoading } = this.state;
        return (
            <>
             <Helmet>
                <title>Contact | Kazh.com</title>
            </Helmet>

            {isLoading ? (
          <Preloader />
        ) : (
          <>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1 data-aos="fade-down">Contact Us.</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>


                <section id="contact">
                    <div class="container-fluid">
                
                        <div class="row justify-content-center align-items-center mt-lg-5 mt-5">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center pl-lg-5" data-aos="fade-right">

                                <h1 data-aos="fade-down">Have a<br/>question?</h1>
                                <h6>Call us directly</h6>
                                <p>India: <a href="tel:+91 8939677750">+91 8939677750</a></p>
                                <p>International: <a href="tel:+1 (302) 8773500">+1 (302) 8773500</a></p>
                                <h6>Email us at</h6>
                                <p> <a href="mailto:hello@kazh.com">hello@kazh.com</a></p>

                            </div>
                            <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 ">
                                <div class="col-lg-12">
                                    <div class="card" style={{backgroundColor: '#ffffff',}}>



                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <h1 class="text-lg-center text-md-center text-center">Make the switch<br/>to smarter company<br/>spending today.</h1>
                                        </div>
                                        <div class="col-lg-10 text-md-center">
                                            <div class="row ">
                                                <div class="col-lg-5 col-md-12 my-md-3  get_Started">
                                                    <a target="_blank"
                                                        href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"><button>Get
                                                            Started</button></a>
                                                </div>
                                                <div class="col-lg-7  col-md-12 demo">
                                                    <a target="_blank" rel="noopener"href="https://calendly.com/kazhcom"><button>Book a
                                                        Demo</button></a>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section data-aos="fade-right" id="full_image_text" style={{backgroundColor: '#ffffff',}}>
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center text-center">


                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-block d-none">

                                <img width="100%" src="./images//about_3.jpg" alt=""  style={{ width:'1000px', height:'670px',margin:'0 auto' }}/>

                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-block">

                                <img width="100%" src="./images//about_3.jpg" alt=""  style={{ width:'100%', height:'100%',margin:'0 auto' }}/>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                </>
        )}
            </>
        );
    }
}

export default Contact;
