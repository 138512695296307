import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import M2MPayments from "./components/M2MPayments";
import Budgets from "./components/Budgets";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import TokenisedBankLiabilities from "./components/TokenisedBankLiabilities";
// import CBDCWallet from "./components/CBDCWallet";
import FAQ from "./components/FAQ";
import DigitalCurrencies from "./components/DigitalCurrencies";
import FractionalAssets from "./components/FractionalAssets";
import PmIm from "./components/Pm-Im";
import CBDCWallet from "./components/wallet";
import Siri from "./components/Siri";






class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundUrl: "",
    };
  }
  async componentDidMount() {}
  state = {};
  render() {
    return (
      <Router >
        <Routes>
            <Route path="/" element={<Home />}  />
            <Route path="/about" element={<About />} />
            <Route path="/m2m-payments" element={<M2MPayments />} />
            <Route path="/budgets" element={<Budgets />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/tokenised-bank-liabilities" element={<TokenisedBankLiabilities />} />
            {/* <Route path="/cbdc-wallet" element={<CBDCWallet />} /> */}
            <Route path="/faq" element={<FAQ />} />
            <Route path="/digital-currencies" element={<DigitalCurrencies />} />
            <Route path="/fractional-assets" element={<FractionalAssets />} />
            <Route path="/pm-im" element={<PmIm />} />
            <Route path="/cbdc-wallet" element={<CBDCWallet />} />

            {/*<Route path="/wallet" element={<Wallets />} />*/}
            {/* <Route path="/detail" element={<Siri />} /> */}

        </Routes>
      </Router>
    );
  }
}

export default App;
