import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class VirtualCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }
    
      componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };

    render() {
        const { isLoading } = this.state;
        return (
            <>
             <Helmet>
                <title>Programmable Money (PM) - Intentional Money (IM) | Kazh.com</title>
            </Helmet>
            {isLoading ? (
          <Preloader />
        ) : (
          <>
                <section id="banner">
                  <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1 data-aos="fade-down">Programmable Money (PM) - Intentional Money (IM)</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1>
                                Digital currency with conditions</h1>
                                <p>Programmable money refers to digital currency that can be assigned conditions about its use by leveraging blockchain-based smart contracts. 
</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Trust created</h1>
                                <p data-aos="fade-down">Programmable money can create trust among all participants by providing a transparent record of financial transactions. 
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/em_logo_Card.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-7.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Speed of the business accelerated</h1>
                                <p data-aos="fade-down">
                                Programmable money allows the many layers of transaction processes to be combined, accelerating the speed of business and payment settlement</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Way maker</h1>
                                <p data-aos="fade-down">The advantages of Programmable Money include improved efficiency, transparency, security and trusted way of handling currencies in a digitally. This also paves way for creating innovative financial products. </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>

               
                <Footer />
                </>
        )}
            </>
        );
    }
}

export default VirtualCards;

