import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Helmet } from "react-helmet";

import Preloader from "../common/Preloader";

document.title = "Home | Kazh.com";
class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fakeDataFetch();
  }

  fakeDataFetch = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
  };
  render() {
    const { isLoading } = this.state;
    return (
      <>
          <Helmet>
          <title>Home - Kazh.com | Programmable Money Protocol</title>
        </Helmet>

        {isLoading ? (
          <Preloader />
        ) : (
          <>
        <section id="banner">
          <Header />

          <div class="container-fluid">
            <div class="row justify-content-lg-center">
              <div class="col-lg-6 ban_left col-md-6 col-sm-12 col-12 pt-lg-0 pt-5 text-lg-left text-md-left text-center">
                <h2 class="pt-lg-0 pt-5">Unlocking <br/> the future of finance</h2>
                <h1>Programmable money</h1>
                <p> Revolutionizing currency by
                  ushering in a new era of money with efficient financial systems
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 get_Started">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"
                    >
                      <button>Get Started</button>
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 demo">
                    <NavLink to="/cbdc-wallet"><button> CBDC Wallet</button></NavLink>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 ban_right col-md-6 col-sm-12 col-12 text-lg-left text-md-center mt-lg-0 mt-md-5 text-center  pt-lg-0 pt-md-5">
                <img width="100%" src="./images/ProgrammableMoney_kazhFintech.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="build">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <h1 data-aos="fade-down" class="text-lg-left text-center">Next-gen programmable money</h1>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 mt-lg-0  mt-md-5 col-12 mt-5">
                <p class="text-lg-left text-md-center text-center m-auto">
                  A holistic solution for the finance of tomorrow, providing a unified platform with versatile programmable money capabilities. Programmability could be one of the major benefits of digital currencies and has the potential to transform the way we use money in the future.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="text_image">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2 order-2">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Digital Currency</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                  CBDC (Central Bank Digital Currency) - The future of money
                </h1>
                <p>
                  Our comprehensive CBDC (Central Bank Digital Currency) service is tailored specifically for central banks seeking to navigate the complexities of digital currency implementation.
                </p>
                <a class="learn" href="/#">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/digital-currencies"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1">
                <img
                  data-aos="fade-right"
                  src="./images/CBDC_KazhFintech.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="image_text">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12  mt-lg-0">
                <img
                  data-aos="fade-left"
                  width="100%"
                  src="./images/Purpose-bound-money-kazh.png"
                  alt=""
                />
              </div>
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center mt-lg-0 mt-5 mt-md-5">
                <h6 data-aos="fade-down">Purpose Bound Money</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-md-3">
                  Intentional Money (IM) - Money with a designated purpose
                </h1>
                <p>
                  Purpose Bound Money refers to a protocol that defines specific conditions governing the utilization of digital currency.
                </p>
                <a class="learn" href="/#">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/pm-im"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="text_image">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2 wrap">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Wrapped Token</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">Bank Token (BT) / Grant Token (GT)</h1>
                <p>
                Wrapped tokens based on ERC-1155 can represent multiple assets within a single contract. These tokens are issued with an aim of developing innovative payment instruments.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/tokenised-bank-liabilities"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right  text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1">
                <img
                  width="70%"
                  data-aos="fade-right"
                  src="./images/Tokenised_Bank_Liabilities_Kazh.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="image_text">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 cbdc_wallet">
                <img
                  width="100%"
                  data-aos="fade-left"
                  src="./images/CBDC_UserWallet_kazh.png"
                  alt=""
                />
              </div>
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center mt-lg-0 mt-5 mt-md-5">
                <h6 data-aos="fade-down">Digital Wallet</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                CBDC User Wallet
                </h1>
                <p>
                Through CBDC wallet, users can send and receive digital Assets (e.g. Intended Money (IM)) and their respective underlying digital money.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/cbdc-wallet"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          id="text_image"
          style={{ backgroundColor: "rgb(248,249,255)" }}
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Autonomous Transactions</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                Machine-to-Machine payments (M2M)
                </h1>
                <p>
                Machine-to-Machine (M2M) payments refer to transactions where digital currencies are exchanged between autonomous devices or systems without human intervention.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/m2m-payments"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1 mtm">
                <img
                  data-aos="fade-right"
                  width="100%"
                  src="./images/m2m_Payments_Kazh.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        
        <section id="image_text">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 fraction">
                <img
                  // width="70%"
                  data-aos="fade-left"
                  src="./images/FractionalAssets_Kazh.png"
                  alt=""
                />
              </div>
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center mt-lg-0  mt-md-5">
                <h6 data-aos="fade-down">Co-Ownership</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                Tokenizing real-world assets into fractional tokens
                </h1>
                <p>
                Fractionalization allows real world  assets to be divided into smaller, more affordable units, making them accessible to a broader range of investors
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/tokenised-bank-liabilities"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="featured">
          <div class="container-fluid">
            <div class="row justify-content-center text-center">
              <div class="col-lg-12 left col-md-12 col-sm-12">
                <div class="text" data-aos="fade-down">
                  <h1>Featured on</h1>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section id="daily">
          <div class="container-fluid">
            <div class="row justify-content-center text-center">
              <div class="col-lg-12 left col-md-12 col-sm-12 col-12">
                <h3>We empower the seamless and adaptable deployment of digital currency—a solution designed to accommodate diverse use cases with reliability, security, and scalability.</h3>
              {/* <a href="https://df.media/these-are-the-top-fintech-financial-technology-companies-in-delaware-2021/" target="_blank"><img width="30%" src="./images/Daily-finance.png" alt="" /></a> */}
              </div>
            </div>
          </div>
        </section>

        <Footer />
        </>
        )}
      </>
    );
  }
}

export default Home;
