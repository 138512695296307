import React, { Component } from "react";
import { BrowserRouter as Link, NavLink } from "react-router-dom";

class Header extends Component {

  
  // constructor(props) {
  //   super(props);
  //   this.state = {isShow: false};
  // }

  // handleClick = () => {
  //   this.setState({ isShow: !this.state.isShow })
  // }
  constructor(props) {
    super(props);
    this.state = this.state = {
      isMenuVisible: false,
      isProductsVisible: false,
      isResourcesVisible: false
    };
  }

  handleMenuToggle = () => {
    this.setState(prevState => ({ isMenuVisible: !prevState.isMenuVisible }));
  };
  handleProductsToggle = () => {
    this.setState(prevState => ({
      isProductsVisible: !prevState.isProductsVisible,
      isResourcesVisible: false // Close Resources menu if open
    }));
  };

  handleResourcesToggle = () => {
    this.setState(prevState => ({
      isResourcesVisible: !prevState.isResourcesVisible,
      isProductsVisible: false // Close Products menu if open
    }));
  };

  render() {
    const { isMenuVisible, isProductsVisible, isResourcesVisible } = this.state;
    return (
      <>

      <nav class="navbar navbar-expand-lg d-lg-block d-sm-none d-md-none d-none">
        <div class="navbar">
          <i class="bx bx-menu"></i>
          <div class="logo">
            <a href="/#" rel="noopener noreferrer">
              <NavLink to="/">
                <img src="./images/kazh_logo.png" alt="" />
              </NavLink>
            </a>
          </div>
          <div class="nav-links">
            <div class="sidebar-logo">
              <img width="50%" src="./images/kazh_logo.png" alt="" />
              <i class="bx bx-x"></i>
            </div>
            <ul class="links">
              <li>
                <a href="/#" rel="noopener noreferrer">
                  <NavLink to="/">Home</NavLink>
                </a>{" "}
              </li>
              <li class="sub">
                <a href="#" rel="noopener noreferrer">Products</a>
                <i class="bx bxs-chevron-down htmlcss-arrow arrow"></i>
                <ul class="htmlCss-sub-menu sub-menu">
                  {/* <li>
                    <a href="/#">
                      <NavLink to="/wallet">Wallet</NavLink>
                    </a>
                  </li> */}
                  <li>
                    {" "}
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/digital-currencies">Digital Currencies</NavLink>
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/pm-im">PM-IM</NavLink>
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/tokenised-bank-liabilities">
                      Tokenised Bank Liabilities
                      </NavLink>
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/cbdc-wallet">
                      CBDC Wallet
                      </NavLink>
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/m2m-payments">
                      M2M Payments
                      </NavLink>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/fractional-assets">Fractional Assets</NavLink>{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/budgets">Budgets</NavLink>{" "}
                    </a>
                  </li> */}
                  <li class="more d-none">
                    <span>
                      <a href="#" rel="noopener noreferrer">More</a>
                      <i class="bx bxs-chevron-right arrow more-arrow"></i>
                    </span>
                    <ul class="more-sub-menu sub-menu">
                      <li>
                        <a href="#" rel="noopener noreferrer">Neumorphism</a>
                      </li>
                      <li>
                        <a href="#" rel="noopener noreferrer">Pre-loader</a>
                      </li>
                      <li>
                        <a href="#" rel="noopener noreferrer">Glassmorphism</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li class="sub">
                <a href="#" rel="noopener noreferrer">Resources</a>
                <i class="bx bxs-chevron-down js-arrow arrow "></i>
                <ul class="js-sub-menu sub-menu">
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/about">About Us</NavLink>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#">
                      <NavLink to="/detail">Details</NavLink>
                    </a>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://medium.com/@kazh"
                    >
                      Blog{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/#" rel="noopener noreferrer">
                      <NavLink to="/faq">FAQs</NavLink>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                    >
                      Podcast{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/#" rel="noopener noreferrer">
                  <NavLink to="/cbdc-wallet">CBDC Wallet</NavLink>
                </a>
                {/* <a href="/#">
                  <NavLink to="/about">About Us</NavLink>
                </a> */}
              </li>
              {/* <li>
                <a href="/#">
                  <NavLink to="/wallet">Wallet</NavLink>
                </a>
              </li> */}
              <li>
                <a href="/#" rel="noopener noreferrer">
                  <NavLink to="/contact">Contact Us</NavLink>
                </a>
              </li>
            </ul>
          </div>
          <div class="search-box d-lg-block d-md-none d-sm-none d-none">
            <i class="bx bx-search d-none"></i>
            <div class="input-box d-none">
              <input type="text" placeholder="Search..." />
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"
            >
              <button>Get Started</button>
            </a>
          </div>
        </div>
      </nav>
      <div
        id="mobile_nav"
        class="d-lg-none d-md-block d-sm-block d-block fixed-top"
      >
        <div class="menu-btn" style={{ textAlign: "left" }} onClick={this.handleMenuToggle}>
          <img className="text-left" src="./images/menu-icon.svg" alt=""  />
        </div>
        <a className="mob_logo_a " rel="noopener noreferrer" href="/">
          <img
            className="mob_logo mt-4"
            src="./images/kazh_logo.png"
            alt=""
          />
        </a>
        {isMenuVisible && (
        <div class="side-bar">
          <header>
            <div class="close-btn" onClick={this.handleMenuToggle}>
              <i class="fas fa-times"></i>
            </div>
          </header>
          
          <div class="menu">
            <div class="item">
              <NavLink to="/">
                <a class="nav-link" rel="noopener noreferrer" href="/#">
                  Home
                </a>
              </NavLink>{" "}
            </div>
            <div class="item">
              <a class="sub-btn" rel="noopener noreferrer" onClick={this.handleProductsToggle}>
                Products<i class="fas fa-angle-right dropdown"></i>
              </a>
              {isProductsVisible && (
              <div class="sub-menu">
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  {" "}
                  <NavLink to="/digital-currencies">Spend Control</NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/pm-im">Virtual Cards</NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/employee-debit-card">
                    Employee Debit Cards
                  </NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/expense-reimbursements">
                    Expense Reimbursements
                  </NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/accounting">Accounting Automation</NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/spending-report">Spending Report</NavLink>
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/budgets">Budgets</NavLink>
                </a>
              </div>
              )}
            </div>
            <div class="item">
              <a class="sub-btn" rel="noopener noreferrer" onClick={this.handleResourcesToggle}>
                Resources<i class="fas fa-angle-right dropdown"></i>
              </a>
              {isResourcesVisible && (
              <div class="sub-menu">
                <a
                  class="dropdown-item sub-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://medium.com/@kazh"
                >
                  Blog{" "}
                </a>
                <a class="dropdown-item sub-item" rel="noopener noreferrer">
                  <NavLink to="/faq">FAQs</NavLink>
                </a>
                <a
                  class="dropdown-item sub-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                >
                  Podcast{" "}
                </a>
              </div>
               )}
            </div>
            <div class="item">
              <a class="nav-link" href="/#" rel="noopener noreferrer"> 
                {/* <NavLink to="/about">About Us</NavLink> */}
                <NavLink to="/cbdc-wallet">CBDC Wallet</NavLink>
              </a>
            </div>
            <div class="item">
              <a class="nav-link" href="/#" rel="noopener noreferrer"> 
                <NavLink to="/about">About Us</NavLink>
              </a>
            </div>
            <div class="item">
              <a class="nav-link" href="/#" rel="noopener noreferrer">
                <NavLink to="/contact">Contact Us</NavLink>
              </a>
            </div>
          </div>
        </div>
           )}
      </div>
    </>
    );
  }
}

export default Header;
