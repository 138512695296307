import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie-player";
import buy from "./lotties/123608-crypto-wallet.json";
import exchange from "./lotties/86719-cryptocurrency.json";
import spend from "./lotties/119089-crypto.json";
import send from "./lotties/70393-crypto-app-for-crypto-coins.json";
import cashout from "./lotties/94447-bitcoin-wallet.json";
import one from "./lotties/106680-login-and-sign-up.json";
import two from "./lotties/122387-green-bitcoin-with-wallet.json";
import three from "./lotties/43059-bitcoin-trade.json";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Preloader from "../common/Preloader";

class Wallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fakeDataFetch();
  }

  fakeDataFetch = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
  };

  State = {
    options: {
      loop: false,
      margin: 10,
      nav: false,
      dots: true,
      autoplaySpeed: true,
      autoplay: true,
      smartSpeed: 250,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        800: {
          items: 2,
        },
        1000: {
          items: 5,
        },
      },
    },
  };
  // State = {
  //   responsive: {
  //     0: {
  //         items: 1,
  //     },
  //     600: {
  //         items: 1,
  //     },
  //     1000: {
  //         items: 5,
  //     },
  // },
  // }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>Wallet | Kazh.com</title>
        </Helmet>

        {isLoading ? (
          <Preloader />
        ) : (
          <>
        <section id="banner">
          <Header />

          <div class="container-fluid title">
            <div class="row justify-content-center">
              <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">
                <div class="text">
                  <h1>CBDC Wallet</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="wallet">
          <div className="container-fluid">
            <div className="row justify-content-center text-center">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12 pt-lg-0 pt-5 ">
                {/* <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-Crypto-tab"
                      data-toggle="pill"
                      data-target="#pills-Crypto"
                      type="button"
                      role="tab"
                      aria-controls="pills-Crypto"
                      aria-selected="true"
                    >
                      Crypto Wallet
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link "
                      id="pills-Buy-tab"
                      data-toggle="pill"
                      data-target="#pills-Buy"
                      type="button"
                      role="tab"
                      aria-controls="pills-Buy"
                      aria-selected="false"
                    >
                      Buy
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Exchange-tab"
                      data-toggle="pill"
                      data-target="#pills-Exchange"
                      type="button"
                      role="tab"
                      aria-controls="pills-Exchange"
                      aria-selected="false"
                    >
                      Exchange
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Cashout-tab"
                      data-toggle="pill"
                      data-target="#pills-Cashout"
                      type="button"
                      role="tab"
                      aria-controls="pills-Cashout"
                      aria-selected="false"
                    >
                      Cashout
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Send-tab"
                      data-toggle="pill"
                      data-target="#pills-Send"
                      type="button"
                      role="tab"
                      aria-controls="pills-Send"
                      aria-selected="false"
                    >
                      Send
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-Spend-tab"
                      data-toggle="pill"
                      data-target="#pills-Spend"
                      type="button"
                      role="tab"
                      aria-controls="pills-Spend"
                      aria-selected="false"
                    >
                      Spend
                    </button>
                  </li>
                </ul> */}

                <div
                  // class="tab-pane fade show active"
                  id="pills-Crypto"
                  role="tabpanel"
                  // aria-labelledby="pills-Crypto-tab"
                >
                  <div className="row justify-content-center d-lg-block d-none">
                    <div className="col-lg-12 ">
                      <h1>Ultimate CBDC wallet</h1>
                    </div>
                    <div className="col-lg-10  mx-auto">
                      <p>
                        kazh.com is a powerful, all-in-one multi-coin wallet,
                        compatible with both mobile and desktop devices.
                        Boasting matchlessly generous functionality, this nifty
                        app allows you to buy, trade, store, cashout, spend,
                        send, and invest your digital holdings from a single
                        dashboard
                      </p>
                      <div className="card pt-3">
                        <img
                          width="100%"
                          src="./images/kazh_dashboard.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 pt-5">
                      <div className="row" id="cards">
                        <div className="col-lg-4 card1">
                          <div className="col-lg-12 card1-1  ">
                            <i class="ri-arrow-right-line"></i>
                            <h3>About ultimate multi crypto wallet</h3>
                          </div>
                          <div className="col-lg-12 card1-2 pr-0 text-left">
                            <h2>Exchange</h2>
                            <h3 className="pr-3">
                              exchange 200+ crypto pairs with the AI-based
                              matching system
                            </h3>

                            <div className="row justify-content-between align-bottom">
                              <div className="col-2">
                                <h4>
                                  <span>03</span>
                                </h4>
                              </div>
                              <div className="col-7 text-right  align-bottom">
                                <Lottie
                                  loop
                                  animationData={exchange}
                                  play
                                  // style={{ width: 150, height: 150 }}
                                />
                                {/* <img
                                    width="95%"
                                    src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fexchange.png&w=256&q=75"
                                    alt=""
                                  /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 card2 ">
                          <div className="col-lg-12 card2-1 pr-0  text-left">
                            <h2>Buy</h2>
                            <h3 className="pr-3">
                              purchase crypto instantly and for free with any
                              bank card
                            </h3>

                            <div className="row justify-content-between align-bottom">
                              <div className="col-2">
                                <h4>
                                  <span>01</span>
                                </h4>
                              </div>
                              <div className="col-7 text-right  align-bottom">
                                {/* <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_O1b0iWuPju.json"
          style="width: 250px; height: 200px;" background="transparent" speed="1" loop autoplay></lottie-player> */}
                                <Lottie
                                  loop
                                  animationData={buy}
                                  play
                                  // style={{ width: 150, height: 150 }}
                                />

                                {/* <img
                                    width="95%"
                                    src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fbuy.png&w=256&q=75"
                                    alt=""
                                  /> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 card2-2 pr-0 text-left">
                            <h2>Spend</h2>
                            <h3 className="pr-3">
                              pay with a global crypto VISA card or top up
                              mobile on-the-go
                            </h3>

                            <div className="row justify-content-between align-bottom">
                              <div className="col-2">
                                <h4>
                                  <span>04</span>
                                </h4>
                              </div>
                              <div className="col-7 text-right  align-bottom">
                                <Lottie
                                  loop
                                  animationData={spend}
                                  play
                                  // style={{ width: 150, height: 150 }}
                                />
                                {/* <img
                                    width="95%"
                                    src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fspend.png&w=256&q=75"
                                    alt=""
                                  /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 card3">
                          <div className="col-lg-12 card3-1 pr-0 text-left">
                            <h2>Send</h2>
                            <h3 className="pr-3">
                              send money around the world for free with just a
                              phone number
                            </h3>

                            <div className="row justify-content-between align-bottom">
                              <div className="col-2">
                                <h4>
                                  <span>02</span>
                                </h4>
                              </div>
                              <div className="col-7 text-right  align-bottom">
                                <Lottie
                                  loop
                                  animationData={send}
                                  play
                                  // style={{ width: 150, height: 150 }}
                                />
                                {/* <img
                                    width="95%"
                                    src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fsend.png&w=256&q=75"
                                    alt=""
                                  /> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 card3-2 pr-0 text-left">
                            <h2>Cash out</h2>
                            <h3 className="pr-3">
                              cashout crypto instantly to your mastercard & visa
                            </h3>

                            <div className="row justify-content-between align-bottom">
                              <div className="col-2">
                                <h4>
                                  <span>05</span>
                                </h4>
                              </div>
                              <div className="col-7 text-right  align-bottom">
                                {/* <img
                                    width="95%"
                                    src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fcash-out.png&w=256&q=75"
                                    alt=""
                                  /> */}
                                <Lottie
                                  loop
                                  animationData={cashout}
                                  play
                                  // style={{ width: 150, height: 150 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row  justify-content-center d-lg-none d-md-block d-sm-block d-block">
                    <OwlCarousel className="owl-theme" {...this.State.options}>
                      <div class="item">
                        <div className="col-lg-12 card1-1  align-bottom">
                          <i class="ri-arrow-right-line"></i>
                          <h3 className="align-bottom pt-4">
                            About ultimate multi crypto wallet
                          </h3>
                        </div>
                      </div>
                      <div class="item">
                        <div className="col-lg-12 carousel-card pr-0  text-left">
                          <h2>Buy</h2>
                          <h3>
                            purchase crypto instantly and for <br /> free with
                            any bank card
                          </h3>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>01</span>
                              </h4>
                            </div>
                            <div className="col-7 text-right  align-bottom">
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fbuy.png&w=256&q=75"
                                  alt=""
                                /> */}
                              <Lottie
                                loop
                                animationData={buy}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div className="col-lg-12 carousel-card pr-0  text-left">
                          <h2>Send</h2>
                          <h3>
                            send money around the world for free with just a
                            phone number
                          </h3>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>02</span>
                              </h4>
                            </div>
                            <div className="col-7 text-right  align-bottom">
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fsend.png&w=256&q=75"
                                  alt=""
                                /> */}
                              <Lottie
                                loop
                                animationData={send}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div className="col-lg-12 carousel-card pr-0 text-left">
                          <h2>Exchange</h2>
                          <h3>
                            exchange 200+ crypto pairs with the AI-based
                            matching system
                          </h3>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>03</span>
                              </h4>
                            </div>
                            <div className="col-7 text-right  align-bottom">
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fexchange.png&w=256&q=75"
                                  alt=""
                                /> */}
                              <Lottie
                                loop
                                animationData={exchange}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div className="col-lg-12 carousel-card pr-0 text-left">
                          <h2>Spend</h2>
                          <h3>
                            pay with a global crypto VISA card or top up mobile
                            on-the-go
                          </h3>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>04</span>
                              </h4>
                            </div>
                            <div className="col-7 text-right  align-bottom">
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fspend.png&w=256&q=75"
                                  alt=""
                                /> */}
                              <Lottie
                                loop
                                animationData={spend}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div className="col-lg-12 carousel-card pr-0 text-left">
                          <h2>Cash out</h2>
                          <h3>
                            cashout crypto instantly to your mastercard & visa
                          </h3>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>05</span>
                              </h4>
                            </div>
                            <div className="col-7 text-right  align-bottom">
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fcash-out.png&w=256&q=75"
                                  alt=""
                                /> */}
                              <Lottie
                                loop
                                animationData={cashout}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                  <div
                    className="row pt-5 justify-content-center  text-center mx-auto"
                    id="steps"
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                      <h1>Get started in 3 simple steps</h1>
                      <p>it only takes a few minutes</p>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 pr-0 text-left">
                        <div className="steps-card">
                          <h2>Sign up at kazh.com</h2>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>01</span>
                              </h4>
                            </div>
                            <div className="col-7 pr-1 text-right  align-bottom">
                              <Lottie
                                loop
                                animationData={one}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                              {/* <img
                                  width="95%"
                                  src="https://kazh.com/_next/image?url=%2Fpic%2Fwallet%2Fsimple-steps-1.png&w=256&q=75"
                                  alt=""
                                /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 pr-0 text-left">
                        <div className="steps-card">
                          <h2>Create a new wallet</h2>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>02</span>
                              </h4>
                            </div>
                            <div className="col-7 pr-1 text-right  align-bottom">
                              <Lottie
                                loop
                                animationData={two}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 pr-0 text-left">
                        <div className="steps-card">
                          <h2>Get some crypto</h2>

                          <div className="row justify-content-between align-bottom">
                            <div className="col-2">
                              <h4>
                                <span>03</span>
                              </h4>
                            </div>
                            <div className="col-7 pr-1 text-right  align-bottom">
                              <Lottie
                                loop
                                animationData={three}
                                play
                                // style={{ width: 150, height: 150 }}
                              />
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        </>
        )}
      </>
    );
  }
}

export default Wallets;
