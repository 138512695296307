import React, { Component, useEffect } from "react";
import Lottie from "react-lottie-player";
import kazhlogoanimation from "../components/lotties/kazh_logoanim.mp4.lottie.json";


function Preloader(){
  return (
    <div className="loader">
      <div className="texts-containe">
      <Lottie
                  loop
                  animationData={kazhlogoanimation}
                  play
                  style={{ width: 150, height: 150 }}
                />
                </div>
      </div>
  );
}

export default Preloader;
