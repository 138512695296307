import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Preloader from "../common/Preloader";

class Siri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fakeDataFetch();
  }

  fakeDataFetch = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>
            Spend Control | Kazh.com
          </title>
        </Helmet>

        {isLoading ? (
          <Preloader />
        ) : (
          <>
        <section id="banner">
          <Header />

          <div class="container-fluid title">
            <div class="row justify-content-center">
              <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">
                <div class="text">
                  <h1 data-aos="fade-down">Spend Control</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="bannersiri">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div
                  class="siri"
                  role="heading"
                  aria-level="1"
                  aria-label="Siri"
                >
                  <img
                    class="image1"
                    src="./images/siriimages/hero_siri__dqh8piyszs02_large.svg"
                  />
                  <img
                    class="image2"
                    src="./images/siriimages/hero_iphone__be8lmj5vyy3m_large.jpg"
                  />
                  <img
                    class="image3"
                    src="./images/siriimages/hero_airpods__r0sckzz0jj22_large.jpg"
                  />
                  <img
                    class="image4"
                    src="./images/siriimages/hero_watch__48ug6l1rfyqa_large.jpg"
                  />
                  <img
                    class="image5"
                    src="./images/siriimages/hero_homepod_mini__dw1umuypbzgy_large.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="task">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h1 data-aos="fade-down">
                  Get everyday tasks done with just your voice.
                </h1>

                <h1 data-aos="fade-up">
                  And the strongest privacy of any intelligent assistant.
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section id="go">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                data-aos="fade-down"
              >
                <h1>On the go.</h1>
                <img src="./images/siriimages/go_airpods__ed69m4vdask2_large.png" />
              </div>
            </div>
          </div>
        </section>

        <section id="phone">
          <div class="container-fluid white">
            <div class="row">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 order-lg-1 order-md-1 order-2 text-center"
                data-aos="fade-right"
              >
                <img src="./images/siriimages/go_iphone__rgcqxe88k6y6_medium.png" />
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text order-lg-2 order-md-2 order-1"
                data-aos="fade-left"
              >
                <img src="./images/siriimages/go_quote__bucu4dzbnlau_large.png" />
                <h1>
                  <span>Hey Siri</span>, text LouLou, ‘I’m on my way’
                </h1>
              </div>
            </div>
          </div>

          <div class="container-fluid remindme">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card one" data-aos="fade-right">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_white__bxatq43nffrm_large.png"
                  />
                  <h2>
                    Hey Siri, remind me to water the plants when I get home
                  </h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/go_tile_1__c3xn44p0q22q_large.png"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card two" data-aos="fade-left">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_black__dwoyxcfdlx2e_large.png"
                  />
                  <h2> Hey Siri, where did I park my car?</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/winding_down_tile_1.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="car">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                data-aos="fade-down"
              >
                <h1>In the car.</h1>
                <h5>
                  Drive safer with Siri and CarPlay.* Get directions, make
                  calls, and let friends know when you’ll arrive — while you
                  keep your eyes on the road and your hands on the wheel.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="siri_car">
          <div class="container-fluid white">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text"></div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text"
                data-aos="fade-left"
              >
                <img src="./images/siriimages/car_quote.png" />
                <h1>
                  <span>Hey Siri</span>, give me directions home
                </h1>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5"
                data-aos="fade-up"
              >
                <img
                  class="rounded mx-auto d-block ca"
                  src="./images/siriimages/car_carplay.png"
                />
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card two" data-aos="fade-right">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_black__dwoyxcfdlx2e_large.png"
                  />
                  <h2> Hey Siri, call Yolanda</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/car_tile_1.jpg"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card one" data-aos="fade-left">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_white__bxatq43nffrm_large.png"
                  />
                  <h2>Hey Siri, share my ETA with Vera</h2>
                  <img
                    class="rounded mx-auto d-block mt-5"
                    src="./images/siriimages/car_tile_2.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="working_out">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                data-aos="fade-down"
              >
                <h1>Working out.</h1>
                <h5>
                  Stay on track. Start a workout, play a favorite playlist, and
                  hear timely texts out loud — all without breaking your stride.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="watch">
          <div class="container-fluid white">
            <div class="row">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text order-lg-1 order-md-1 order-1"
                data-aos="fade-right"
              >
                <img src="./images/siriimages/go_quote__bucu4dzbnlau_large.png" />
                <h1>
                  <span>Hey Siri</span>, start a 30-minute outdoor run
                </h1>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 order-lg-2 order-md-2 order-2"
                data-aos="fade-left"
              >
                <img
                  class="rounded mx-auto d-block"
                  src="./images/siriimages/workout_watch.png"
                />
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card two" data-aos="fade-right">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_black__dwoyxcfdlx2e_large.png"
                  />
                  <h2> Hey Siri, play something upbeat</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/go_airpods__ed69m4vdask2_large.png"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card one" data-aos="fade-left">
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/workout_tile_2.png"
                  />
                  <h2>Cami said, ‘Okay, see you soon!’</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="kitchen">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                data-aos="fade-down"
              >
                <h1>In the kitchen.</h1>
                <h5>
                  Multitask without the mess. Set timers, convert measurements,
                  and call everyone to the table with Intercom on HomePod.
                  Without getting pizza sauce all over your iPad.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="pizza">
          <div class="container-fluid white">
            <div class="row">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text"
                data-aos="fade-right"
              >
                <img src="./images/siriimages/car_quote.png" />
                <h1>
                  <span>Hey Siri</span>,set a pizza timer for 12 minutes
                </h1>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5"
                data-aos="fade-up"
              >
                <img
                  class="rounded mx-auto d-block ca"
                  src="./images/siriimages/kitchen_ipad.png"
                />
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card one" data-aos="fade-right">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_white__bxatq43nffrm_large.png"
                  />
                  <h2>
                    Hey Siri, how many tablespoons are in one-quarter cup?
                  </h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/kitchen_tile_1.png"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card two" data-aos="fade-left">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_black__dwoyxcfdlx2e_large.png"
                  />
                  <h2>Hey Siri, intercom to Daniel’s room, ‘Dinner’s ready’</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/kitchen_tile_2.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="winding">
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                data-aos="fade-down"
              >
                <h1>Winding down.</h1>
                <h5>
                  Kick back and relax. Set your alarm, turn off the lights, and
                  drift off with calming music — all with your eyes closed.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="sound">
          <div class="container-fluid white">
            <div class="row">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text"
                data-aos="fade-right"
              >
                <img src="./images/siriimages/go_quote__bucu4dzbnlau_large.png" />
                <h1>
                  <span>Hey Siri</span>, play ocean sounds
                </h1>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5"
                data-aos="fade-left"
              >
                <img
                  class="rounded mx-auto d-block"
                  src="./images/siriimages/winding_down.png"
                />
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card two" data-aos="fade-right">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_black__dwoyxcfdlx2e_large.png"
                  />
                  <h2>Hey Siri, set an alarm for 8 AM</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/winding_down_tile_1.jpg"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                <div class="card one" data-aos="fade-left">
                  <img
                    class="quote"
                    src="./images/siriimages/quote_white__bxatq43nffrm_large.png"
                  />
                  <h2>Hey Siri, dim the lights</h2>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/winding_down_tile.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="black">
          <div id="private" class="pt-5">
            <div class="container-fluid mt-5">
              <div class="row">
                <div
                  class="col-lg-12 col-md-12 col-sm-12 col-12"
                  data-aos="fade-down"
                >
                  <i class="fa fa-apple"></i>
                  <h1>The most private digital assistant.</h1>
                  <h3>
                    Siri keeps your information private and secure — what you
                    ask Siri isn’t associated with your Apple ID. The power of
                    the Apple Neural Engine ensures that the audio of your
                    requests never leaves your iPhone or iPad unless you choose
                    to share it. On-device intelligence makes your experience
                    with Siri personal — learning your preferences and what you
                    might want — while maintaining your privacy. And, of course,
                    what you share with Siri is never shared with advertisers.
                  </h3>
                  <h6>
                    <a href="" class="mb-5">
                      Learn more about Privacy at Apple
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div id="siridoes">
            <h1 data-aos="fade-down">Siri does all this. And more.</h1>
            <div class="page-content">
              <div class="tabbed">
                <input type="radio" id="tab1" name="css-tabs" checked />
                <input type="radio" id="tab2" name="css-tabs" />
                <input type="radio" id="tab3" name="css-tabs" />
                <input type="radio" id="tab4" name="css-tabs" />
                <input type="radio" id="tab5" name="css-tabs" />
                <input type="radio" id="tab6" name="css-tabs" />
                <input type="radio" id="tab7" name="css-tabs" />
                <input type="radio" id="tab8" name="css-tabs" />
                <input type="radio" id="tab9" name="css-tabs" />

                <ul class="tabs">
                  <li class="tab">
                    <label for="tab1">
                      <i class="fa fa-th" aria-hidden="true"></i>
                      <span>All</span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab2">
                      <i class="fa fa-mobile" aria-hidden="true"></i>
                      <span>
                        Calls and <br />
                        Texts
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab3">
                      <i class="fa fa-list-ol" aria-hidden="true"></i>
                      <span>
                        Knowledge <br /> and Answers
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab4">
                      <i class="fa fa-home"></i>
                      <span>
                        Smart
                        <br /> Home
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab5">
                      <i class="fa fa-list-ol" aria-hidden="true"></i>
                      <span>
                        Everyday
                        <br /> Tasks
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab6">
                      <i class="fa fa-map" aria-hidden="true"></i>
                      <span>
                        Navigation <br />
                        and Maps
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab7">
                      <i class="fa fa-headphones" aria-hidden="true"></i>
                      <span>
                        Music and
                        <br /> Podcasts
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab8">
                      <i class="fa fa-film" aria-hidden="true"></i>
                      <span>
                        Tv and <br />
                        Movies
                      </span>
                    </label>
                  </li>
                  <li class="tab">
                    <label for="tab9">
                      <i class="fa fa-flag-checkered" aria-hidden="true"></i>
                      <span>Sports</span>
                    </label>
                  </li>
                </ul>
                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card purple">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card purple">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card purple">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card purple">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card purple">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card orange">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card orange">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card orange">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card orange">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card orange">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card blue">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card blue">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card blue">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card blue">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card blue">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card green">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card green">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card green">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card green">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card green">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card red">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card red">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card red">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card red">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card red">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card violet">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card violet">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card violet">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card violet">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card violet">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card lavender">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card lavender">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card lavender">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card lavender">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card lavender">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>

                <div class="tab-content">
                  <OwlCarousel className="owl-theme" loop margin={1} nav>
                    <div class="item">
                      <div class="card dgreen">
                        <p>Knowledge and Answers</p>
                        <h3>Hey Siri, how’s the stock market doing?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card dgreen">
                        <p>Everyday Tasks</p>
                        <h3>Hey Siri, where are my keys?</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card dgreen">
                        <p>Calls and Texts</p>
                        <h3>Hey Siri, play my voicemails</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card dgreen">
                        <p>Music and Podcasts</p>
                        <h3>Hey Siri, play Invisibilia song</h3>
                      </div>
                    </div>
                    <div class="item">
                      <div class="card dgreen">
                        <p>Sports</p>
                        <h3>Hey Siri, what NFL games are on?</h3>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="go_further">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <h1 data-aos="fade-down">Go even further with Siri.</h1>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5"
                data-aos="fade-right"
              >
                <div class="card one text-center">
                  <h2>Use Siri on Your Apple Devices</h2>
                  <a href="#">
                    Learn how &nbsp;
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/routers_tile_1.png"
                  />
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5"
                data-aos="fade-down"
              >
                <div class="card two text-center">
                  <h2>Shortcuts User Guide</h2>
                  <a href="#">
                    <h6>
                      Learn more about the Shortcuts app &nbsp;
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </h6>
                  </a>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/routers_tile_2.png"
                  />
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5"
                data-aos="fade-left"
              >
                <div class="card three text-center">
                  <h2>Siri for Developers</h2>
                  <a href="#">
                    <h6>
                      Learn more about SiriKit &nbsp;
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </h6>
                  </a>
                  <img
                    class="rounded mx-auto d-block"
                    src="./images/siriimages/routers_tile_3.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
        </>
        )}
      </>
    );
  }
}

export default Siri;
