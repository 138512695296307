import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Helmet } from "react-helmet";

import Preloader from "../common/Preloader";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fakeDataFetch();
  }

  fakeDataFetch = () => {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1500);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>About | Kazh.com</title>
        </Helmet>

        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <section id="banner">
              <Header />

              <div class="container-fluid title">
                <div class="row justify-content-center">
                  <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">
                    <div class="text">
                      <h1>About Us.</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="full_image_text"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="container-fluid">
                <div class="row justify-content-center align-items-center text-center">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-none">
                    <img
                      width="100%"
                      src="./images/about_1.png"
                      alt=""
                      style={{ width: "920px" }}
                    />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-none d-block">
                    <img
                      width="100%"
                      src="./images/about_1.png"
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="about">
              <div class="container-fluid">
                <div class="row justify-content-center align-items-center mt-lg-5">
                  <div className="col-lg-12">
                  <h1 data-aos="fade-down">
                    Unlocking the Future: Programmable Money and M2M Payments
                    </h1>
                  </div>
                  <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2 pt-lg-0 pt-5">
                    {/* <h1 data-aos="fade-down">
                    Unlocking the Future: Programmable Money and M2M Payments
                    </h1> */}
                    <p data-aos="fade-down">
                    At Kazh, we are crafting the future of finance with a holistic approach that integrates advanced technologies and innovative solutions. Our unified platform offers versatile programmable money capabilities, including Central Bank Digital Currency (CBDC) based machine-to-machine (M2M) payments. This allows for seamless automation of transactions between devices, enhancing efficiency and accuracy in financial operations. By leveraging the power of digital currencies, Kazh empowers organizations to streamline their payment processes, reduce costs, and enhance security. Our platform supports a wide range of digital assets, providing users with a comprehensive suite of tools for managing their financial transactions, from buying and trading to spending and investing.
                    </p>
                    <p data-aos="fade-down">
                    Programmable money, coupled with CBDC-based M2M payments, represents a significant leap forward in how we use and interact with currency. It allows for the automation of complex financial processes, enabling smart contracts and conditional payments that can be executed without manual intervention. This capability has the potential to revolutionize industries by reducing transaction times, increasing transparency, and minimizing the risk of fraud. At Kazh, we are at the forefront of this transformation, providing businesses with the tools they need to harness the benefits of programmable money and M2M payments. Our commitment to innovation ensures that we deliver secure, efficient, and future-ready solutions for the evolving financial landscape.
                    </p>
                  </div>
                  <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 col-12 d-lg-block d-none order-lg-2 order-1">
                    <img
                      data-aos="fade-left"
                      width="100%"
                      height="100%"
                      src="./images/about_2.png"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 col-12 d-lg-none d-block order-lg-2 order-1">
                    <img
                      data-aos="fade-left"
                      width="50%"
                      src="./images/about_2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <section id="about_meet">
              <div class="container-fluid">
                <div class="row justify-content-center align-items-center mt-lg-5">
                  <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center">
                    <h1 data-aos="fade-down">
                      Meet the
                      <br />
                      Team
                    </h1>
                  </div>
                  <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 ">
                    <p class="mb-5" data-aos="fade-down">
                      Kazh Fintech Private Limited was founded by globally
                      recognized payments and tech leaders looking to re-write
                      the future of payments today. We are a supportive group of
                      innovators, disruptors, and fintech geeks on a mission to
                      make your payments smarter, smoother, and more secure. We
                      believe in building a transparent and dynamic world, and
                      we highly invest in our customers, product and people.
                    </p>

                    <a href="/careers">
                      <button data-aos="fade-right">
                        Join Our Team{" "}
                        <i
                          class="fas fa-arrow-right"
                          style={{ padding: "0 5px 0 10px", fontSize: "15px" }}
                        ></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="full_image_text"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="container-fluid">
                <div class="row justify-content-center align-items-center text-center">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-none">
                    <img
                      width="100%"
                      src="./images/about_3.jpg"
                      alt=""
                      style={{ width: "1000px", height: "670px" }}
                    />
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-none d-block">
                    <img
                      width="100%"
                      src="./images/about_3.jpg"
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </>
        )}
      </>
    );
  }
}

export default About;
