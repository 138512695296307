import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class SpendControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }
    
      componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };
    

    render() {
        const { isLoading } = this.state;
        return (
            <>
            <Helmet>
                <title>Digital Currencies | Kazh.com</title>
            </Helmet>

            
        {isLoading ? (
          <Preloader />
        // <></>
        ) : (
          <>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1 data-aos="fade-down"> Digital currencies ( CBDC)</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right col-md-12 col-sm-12 col-12 text-lg-center text-center">
                                <img width="70%" src="./images/productpng.png" alt=""/>
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1>Traditionally digital</h1>
                                <p>A central bank digital currency (CBDC) acts as traditional banknotes but comes in a programmable, digital form.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Favourable user experience</h1>
                                <p data-aos="fade-down">CBDCs are issued and governed by a country’s central bank, which records transactions on a digital ledger. The faster, cheaper and more efficient transactions  gives a favourable user experience. 
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
              <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Transparency Guarenteed</h1>
                                <p data-aos="fade-down">
                                Additionally CBDC’s are easily traceable which gives greater transparency. Mishandled or counterfeited currency would become virtually impossible. </p>

                            </div>
                        </div>
                    </div>
                </section> 
                 <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Safer transaction ensured</h1>
                                <p data-aos="fade-down">CBDCs eliminate the middleman between a person and their stored funds, which would be issued directly into a virtual wallet. This paves way for a safer transaction in a secure environment.</p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                {/* <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/product-5.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Control on physical cards</h1>
                                <p data-aos="fade-down">
                                    Limit Kazh cards to be used on specific days, during certain time slots, and for precise types of spending.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Effortlessly improve
                                    spending habits</h1>
                                <p data-aos="fade-down">Improve spending habits by setting
                                    up additional post-payment rules to block users with too many unjustified payments.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>  */}

                
                <Footer />
                </>
        )}
            </>
        );
    }
}

export default SpendControl;
