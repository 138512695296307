import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class Accounting extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading:true
        };
    }
    
    
    componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };


    render() {
        const { isLoading } = this.state;
        return (
            <>
            <Helmet>
                <title>M2M Payments | Kazh.com</title>
            </Helmet>

            {isLoading ? (
          <Preloader />
        ) : (
            <>


                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{ width: '80%' }}>
                                    <h1 data-aos="fade-down">M2M Payments</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1>Automated payments</h1>
                                <p>Machine-to-machine payments are changing the payments industry. They are automated, real-time payments made between connected devices With minimal or no human intervention. </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-5 pt-5">Promising future</h1>
                                <p data-aos="fade-down">The future of M2M payments looks promising, as it has the potential to enable a more connected and efficient world.</p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12mt-lg-0 mt-4 mt-md-4 order-lg-1 order-2">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down" >Automation enabled</h1>
                                <p data-aos="fade-down">
                                M2M payments enable the automation of transactions, resulting in more efficient and cost-effective processes. M2M payments have the potential to revolutionize the way we do business and interact with technology.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Export Kazh data to your
                                    accounting automation software</h1>
                                <p data-aos="fade-down" >Use our native integrations and our custom
                                    exports to effortlessly integrate Kazh transactions and receipts into your existing system.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-1 order-2">
                                <img data-aos="fade-right" width="70%" src="./images/productpng.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>



                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down">Consolidate and track all payments</h1>
                                <p data-aos="fade-down">
                                    Manage employee expense reimbursements along with other transactions for better cost centre tracking and
                                    budget follow-up. Receipts are digitised and stored safely.

                                </p>

                            </div>
                        </div>
                    </div>
                </section> */}







               
              
                <Footer />

                </>
        )}
            </>
        );
    }
}

export default Accounting;
