import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

import Preloader from "../common/Preloader";

class Budgets extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }
    
      componentDidMount() {
        this.fakeDataFetch();
      }
    
      fakeDataFetch = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      };

    render() {
        const { isLoading } = this.state;
        return (
            <>
            <Helmet>
                <title>Budgets | Kazh.com</title>
            </Helmet>

            
        {isLoading ? (
          <Preloader />
        ) : (
          <>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{width: '30%',}}>
                                    <h1 data-aos="fade-down">Budgets</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text" style={{backgroundColor: '#ffffff',}}>
                    <div class="container-fluid">
                        <div class="row align-items-center text-center">


                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                <h1 style={{margin:'100px auto' }}>


                                    Empower every employee to be accountable for their piece of the pie.</h1>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down">



                                    Centralise every department and team budget</h1>
                                <p data-aos="fade-down">Connect budgets to operational spend approvals and payments made through
                                    Kazh.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Make informed approvals
                                    on new requests</h1>
                                <p data-aos="fade-down">Let budget owners keep
                                    track of their budgets
                                    & approve spending based on available balance.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/productpng.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down">

                                    Get live overview against what’s been spent</h1>
                                <p data-aos="fade-down">
                                    Budgets are automatically updated in real time based on approved spend commitments and payments
                                    made. This includes purchase orders, card requests, and invoices to pay.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Adjust budgets based on
                                    live information</h1>
                                <p data-aos="fade-down">No more end-of-quarter
                                    surprises, thanks to
                                    real-time visibility on budgets spent compared with what was planned.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-5.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>

                <section id="full_image_text" style={{backgroundColor: '#ffffff',}}>
                    <div class="container-fluid">
                        <div class="row align-items-center text-center">


                            <div class="col-lg-12 col-md-12 col-sm-12" style={{ padding:'0 auto 100px', }}>

                                <h1 data-aos="fade-down" style={{ width: '75%',margin: 'auto', }}>


                                    Turn your budget plan into reality</h1>
                                <p data-aos="fade-down" style={{ width: '35%',margin:'0 auto 75px', }}>Budgets are integrated into your
                                    spending process for
                                    automated,
                                    effortless budget control.</p>

                            </div>

                        </div>
                    </div>
                </section>

                
                <Footer />

                </>
        )}
            </>
        );
    }
}

export default Budgets;
